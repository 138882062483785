import React from "react"
import { Button } from '@material-ui/core';
import html2canvas from 'html2canvas';
import sacoche from '../images/sacoche.svg'
import quickPocket from '../images/quick-pocket.svg'
import mygregory from '../images/mygregory.svg'
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs'
import { useTranslation } from "react-i18next"

const Share = ({ currentDesign, currentInfo, color }) => {
  React.useEffect(() => {
    const canvasFront = document.querySelector('#canvas-container-front').innerHTML;
    const frontImage = document.querySelector('#svgOutput')
    frontImage.innerHTML = canvasFront
  }, []);

  const saveShareImage = () => {
    var node = document.getElementById('sharebox');
    htmlToImage.toPng(node)
    .then(function (dataUrl) {
      download(dataUrl, "mydesign.png", "image/png");
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
  }

  const { t } = useTranslation()

  return (
    <div className="sharePage">
      <h2>{ t('checkout.shareDesign') }</h2>
      <div className="sharebox__container">
        <div id="sharebox" className="sharebox" style={{ backgroundColor: color }}>
          <div id="svgOutput" className="sharebox__svg">
          </div>
          <div className="sharebox__product">
            {currentDesign.product === "sacoche" ? (
              <img
                src={sacoche}
                className="sacoche"
              />
            ) : (
              <img
                src={quickPocket}
                className="quickPocket"
              />
            )}
          </div>
          <div className="sharebox__slogen">
            <img
              src={mygregory}
              className="mygregory"
            />
          </div>
          {/* <div className="sharebox__name">
            { t('checkout.designBy') }<br/>{currentInfo.first_name} {currentInfo.last_name}
          </div>
          <div className="sharebox__link">
            { t('checkout.getAt') }<br/>createmygregory.com
          </div> */}
        </div>
      </div>
      <Button className="basicButton" onClick={() => saveShareImage()}>
        { t('checkout.saveImage') }
      </Button>
    </div>
  )
}

export default Share;