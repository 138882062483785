import React from "react"
import { Button } from '@material-ui/core';
import { defaultStaticContext } from '../context/StaticContext'
import { useTranslation } from "react-i18next"

const Confirmation = ({ currentDesign, currentInfo, setStage, currentLang, uuid, setQrcodelink, data, outofstock, generateColor, currentRegion }) => {
  // const cloneCanvas = (oldCanvas) => {

  //   //create a new canvas
  //   var newCanvas = document.createElement('canvas');
  //   var context = newCanvas.getContext('2d');

  //   //set dimensions
  //   newCanvas.width = oldCanvas.width;
  //   newCanvas.height = oldCanvas.height;

  //   //apply the old canvas to the new one
  //   context.drawImage(oldCanvas, 0, 0);

  //   //return the new canvas
  //   return newCanvas;
  // }
  React.useEffect(() => {
    const canvasFront = document.querySelector('#canvas-container-front').innerHTML;
    const canvasBack = document.querySelector('#canvas-container-back').innerHTML;
    const frontImage = document.querySelector('#front-image')
    const backImage = document.querySelector('#back-image')
    frontImage.innerHTML = canvasFront
    backImage.innerHTML = canvasBack
  }, []);

  const parseFetchResponse = response => response.json().then(text => ({
    json: text,
    meta: response,
  }))

  const confirmOrder = () => {
    const orderInfo = {
      "order_id": uuid,
      "order_status": outofstock ? "registered" : "reserved"
    }
    fetch('https://asia-east2-create-my-gregory-2021-prod.cloudfunctions.net/app/api/orders/' + uuid + '/confirm', {
      method: 'post',
      headers: {
        'Content-Type':'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(orderInfo)
    })
    .then(parseFetchResponse)
    .then(({ json, meta }) => {
      if (!meta.ok) {
      } else {
        generateColor()
        setStage('save')
      }
    })
  }

  const { t } = useTranslation()

  return (
    <div className={"confirmation" + (outofstock ? " confirmation__register" : "")}>
      <h2>{ t('checkout.confirmTitle') }</h2>
      <div
        className="confirmation__wrapper"
        style={{flexDirection: outofstock ? `column` : `row`}}>
        <div
          className="confirmation__bagInfo"
          style={{borderRight: outofstock ? `none` : `1px solid #999`}}
          >
          <div className="confirmation__header">
            <h3>My {data.product_id === "sacoche" ? "Sacoche" : "Quick Pocket"}</h3>
            <div className="price">{defaultStaticContext.price[currentRegion]['unit']} {defaultStaticContext.price[currentRegion][currentDesign.product]}</div>
          </div>
          <div className="confirmation__image">
            <div id="front-image">
            </div>
            <div id="back-image">
            </div>
          </div>
          <ul className="confirmation__design">
            { data.product_case.map((item, i) => {
              return (
                <li key={i}>
                  <h6>{ t('product.component')[item.comp] }</h6>
                  <p>{ t('product.color')[item.color] }</p>
                </li>
              )
            })}
            <li>
              <h6>{ t('product.component.tag') }</h6>
              <p>{data.product_tag}</p>
            </li>
          </ul>
        </div>
        <div className="confirmation__reserveInfo">
          <h3 style={{display: outofstock ? "none" : "block"}}>{ t('checkout.confirmInfo') }</h3>
          <ul style={{display: outofstock ? "none" : "block"}}>
            <li>
              <h6>{ t('checkout.name') }</h6>
              <p>{data.first_name + " " + data.last_name}</p>
            </li>
            <li>
              <h6>{ t('checkout.email') }</h6>
              <p>{data.email}</p>
            </li>
            <li>
              <h6>{ t('checkout.phone') }</h6>
              <p>{data.phone}</p>
            </li>
            <li>
              <h6>{ t('checkout.address') }</h6>
              <p>{t('checkout.storelist')[data.store]}</p>
            </li>
            <li>
              <h6>{ t('checkout.dateTime') }</h6>
              <p>{(defaultStaticContext.datelist[currentInfo.date] ? defaultStaticContext.datelist[currentInfo.date].full : "") + "," + currentInfo.time}</p>
            </li>
          </ul>
          {/* <ul className="confirmation__appointment">
            { Object.keys(currentInfo).map((key, i) => {
              return (
                <li key={i}>
                  <h6>{key}</h6>
                  <p>{currentInfo[key]}</p>
                </li>
              )
            })}
          </ul> */}
          <div className="buttonGroup stickToBottom">
            <Button className="basicButton outline" onClick={() => setStage('form')}>
              { t('back') }
            </Button>
            <Button className="basicButton" onClick={() => confirmOrder()}>
              { t('confirmorder') }
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Confirmation