import React from "react"
import { Link } from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, FormHelperText, Button, InputAdornment } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { defaultStaticContext } from '../context/StaticContext'
import { useTranslation } from 'react-i18next'
import QRCode from 'qrcode'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const Form = ({ currentInfo, updateInfo, setStage, currentDesign, currentRegion, currentLang, setData, setUUID, setQrcodelink, outofstock }) => {
  const classes = useStyles();
  // const [error, setError] = React.useState({
  //   first_name: true,
  //   last_name: true,
  //   email: true,
  //   // reenterEmail: false,
  //   verification_code: true,
  //   phone: true,
  //   store: true,
  //   date: true,
  //   time: true,
  //   agree: true
  // });
  const [timemap, setTimemap] = React.useState([])
  const [text, setText] = React.useState({
    first_name: currentInfo.first_name,
    last_name: currentInfo.last_name,
    email: currentInfo.email,
    verification_code: '',
    phone: currentInfo.phone
  });
  const [selection, setSelection] = React.useState({
    region: currentRegion,
    store: currentInfo.store,
    date: currentInfo.date,
    time: currentInfo.time
  });
  const [labelWidth, setLabelWidth] = React.useState({
    labelA: 0,
    labelB: 0,
    labelC: 0,
    labelD: 0
  });
  const [check, setCheck] = React.useState({
    checkedA: false
  });
  // const inputLabel0 = React.useRef(null);
  const inputLabel1 = React.useRef(null);
  const inputLabel2 = React.useRef(null);
  const inputLabel3 = React.useRef(null);

  // const [filled, setFilled] = React.useState(false)
  // React.useEffect(() => {
    // const newError = {}
    // for (var key in error) {
    //   if (!outofstock) {
    //     if (key !== 'agree') {
    //       setFilled(true)
    //     }
    //   } else {
    //     if (key !== 'agree' && key !== 'region' && key !== 'store' && key !== 'date' && key !== 'time') {
    //       setFilled(true)
    //     }
    //   }
    // }
    // if (check.checkedA) {
    //   filled = true
    //   newError['agree'] = false
    // } else {
    //   newError['agree'] = true
    // }
    // updateErrorState(newError)
  // }, [error]);
  
  React.useEffect(() => {
    setLabelWidth({
      // labelA: inputLabel0.current.offsetWidth,
      labelB: inputLabel1.current.offsetWidth,
      labelC: inputLabel2.current.offsetWidth,
      labelD: inputLabel3.current.offsetWidth
    });
    checkTimeSlot()
  }, [inputLabel1]);
  const handleChangeSelect = name => event => {
    // setSelection({ ...selection, [name]: event.target.value });
    if (name === "store") {
      setSelection({ ...selection, "store": event.target.value, "date": "", "time" : "" });
    } else if (name === "date") {
      setSelection({ ...selection, "date": event.target.value, "time" : "" });
    } else {
      setSelection({ ...selection, "time": event.target.value });
    }
  };
  const handleChangeCheck = name => event => {
    setCheck({ ...check, [name]: event.target.checked });
  };
  const getTextValue = (name, value) => {
    setText({ ...text, [name]: value });
  }
  // const updateErrorState = object => {
  //   setError(object);
  // }

  const saveContent = () => {
    const newInfo = currentInfo
    // const newError = {}
    // let filled = true
    newInfo.first_name = text.first_name
    newInfo.last_name = text.last_name
    newInfo.email = text.email
    newInfo.phone = text.phone
    newInfo.verification_code = text.verification_code
    newInfo.region = currentRegion
    newInfo.store = selection.store
    newInfo.date = selection.date
    newInfo.time = selection.time
    updateInfo(newInfo)
    // for (var key in error) {
    //   if (!outofstock) {
    //     if (key !== 'agree') {
    //       const showError = newInfo[key].length === 0
    //       newError[key] = showError
    //       if (showError) {
    //         filled = false
    //       }
    //     }
    //   } else {
    //     if (key !== 'agree' && key !== 'region' && key !== 'store' && key !== 'date' && key !== 'time') {
    //       const showError = newInfo[key].length === 0
    //       newError[key] = showError
    //       if (showError) {
    //         filled = false
    //       }
    //     }
    //   }
      
    // }
    // if (!check.checkedA) {
    //   filled = false
    //   newError['agree'] = true
    // } else {
    //   newError['agree'] = false
    // }
    // updateErrorState(newError)
    if (errorButton()) {
      const finalData = getData(newInfo, currentDesign, currentLang)
      setData(finalData)
      sendOutData(finalData)
    }
  }

  const checkTimeSlot = () => {
    Promise.all(
      defaultStaticContext.storemap[currentRegion].map(store =>
        fetch('https://asia-east2-create-my-gregory-2021-prod.cloudfunctions.net/app/api/product_stock?store=' + store + '&region=' + currentRegion)
        .then(value => value.json())
      )
    ).then(data => {
      let total = [].concat(...data)
      setTimemap(getAvailableDate(total))
    });
  }
  
  const sendVerificationCode = value => {
    const emailAddress = {
      "email": value
    }
    fetch('https://asia-east2-create-my-gregory-2021-prod.cloudfunctions.net/app/api/verification', {
      method: 'post',
      headers: {
        'Content-Type':'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(emailAddress)
    })
    .then(parseFetchResponse)
    .then(({ meta }) => {
      if (!meta.ok) {
        setEmail_error(true)
      } else {
        setEmail_error(false)
        start()
      }
    })
  }

  const [seconds, setSeconds] = React.useState(30);
  const [isActive, setIsActive] = React.useState(false);

  const start = () => {
    setIsActive(true);
  }

  React.useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else if (!isActive && seconds !== 30) {
      clearInterval(interval);
    }
    if (seconds < 1) {
      setIsActive(false)
      setSeconds(30)
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);
  
  const [email_error, setEmail_error] = React.useState(false)
  const [verification_error, setVerification_error] = React.useState(false)
  const [overOrder_error, setOverOrder_error] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const parseFetchResponse = response => response.json().then(text => ({
    json: text,
    meta: response,
  }))

  const getData = (newInfo, design, lang) => {
    const data = {}
    data.first_name = newInfo.first_name
    data.last_name = newInfo.last_name
    data.email = newInfo.email
    data.verification_code = newInfo.verification_code
    data.phone = newInfo.phone
    if (!outofstock) {
      data.region = newInfo.region
      data.store = newInfo.store
      const newtime = new Date(defaultStaticContext.datelist[newInfo.date].full + "," + newInfo.time)
      data.time = newtime.toISOString()
    } else {
      data.region = newInfo.region
    }
    console.log(data)
    data.product_case = design.case
    if (design.tag !== "") {
      data.product_tag = design.tag
    } else {
      data.product_tag = "-"
    }
    data.product_id = design.product
    data.lang = lang
    return data
  }

  const sendOutData = (data) => {
    fetch('https://asia-east2-create-my-gregory-2021-prod.cloudfunctions.net/app/api/order', {
      method: 'post',
      headers: {
        'Content-Type':'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify(data)
    })
    .then(parseFetchResponse)
    .then(({ json, meta }) => {
      if (!meta.ok) {
        if (json.code === "INVALID_INPUT") {
          setVerification_error(true)
        } else if (json.code === "PRODUCT_OUT_OF_STOCK") {
          setErrorMessage(t('checkout.outofstockError'))
        } else if (json.code === "ORDER_EXISTS") {
          setOverOrder_error(true)
        }
     } else {
        setErrorMessage('')
        setUUID(json.order_id)
        setQrcodelink(generateQrcode(json.order_id))
        setStage('confirmation')
      }
    })
  }

  const generateQrcode = (text) => {
    let canvas
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 1,
      width: 500,
      height: 500,
      margin: 1,
      color: {
        dark:"#212121",
        light:"#fff"
      }
    }
    QRCode.toDataURL(text, opts, function (err, url) {
      if (err) console.error(err)
      canvas = url
    })
    return canvas
  }

  const { t } = useTranslation()

  const errorButton = () => {
    let filled = true
    for (var key in text) {
      if (text[key].length === 0) {
        filled = false
      }
    }
    if (!outofstock) {
      for (var key in selection) {
        if (selection[key].length === 0) {
          filled = false
        }
      }
    }
    if (!check.checkedA) {
      filled = false
    }
    return filled
  }

  const getAvailableDate = (json) => {
    let availableDate = []
    json.map(store => {
      Object.keys(store.quantity).map(function(key, index) {
        if (store.quantity[key] !== 0) {
          let date = new Date(key)
          let monthday
          if (date.getDate() === defaultStaticContext.datelist["date1"].day) {
            monthday = "date1"
          } else if (date.getDate() === defaultStaticContext.datelist["date2"].day) {
            monthday = "date2"
          } else {
            monthday = "date3"
          }
          availableDate.push({ "store": store.store, "date": monthday, "time": date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false}) })
        }
      });
    })
    return availableDate
  }

  return (
  <div className="form">
    <h2>{ outofstock ? t('checkout.formTitleRegister') : t('checkout.formTitle') }</h2>
    <div className="form__desc">{ outofstock ? t('checkout.formDescRegister') : t('checkout.formDesc') }</div>
    <form className="form__form" noValidate autoComplete="off">
      <h3><span style={{display: outofstock ? 'none' : 'inline'}}>1. </span>{ t('checkout.personalInfo') }</h3>
      <TextField
        // error={error.first_name && text.first_name.length === 0}
        id="first-name"
        label={ t('checkout.firstName') }
        style={{ margin: 8, width: `calc(50% - 16px)` }}
        variant="outlined"
        value={text.first_name}
        onChange={(event) => getTextValue('first_name', event.target.value)}
      />
      <TextField
        // error={error.last_name && text.last_name.length === 0}
        id="last-name"
        label={ t('checkout.lastName') }
        style={{ margin: 8, width: `calc(50% - 16px)` }}
        variant="outlined"
        value={text.last_name}
        onChange={(event) => getTextValue('last_name', event.target.value)}
      />
      <TextField
        // error={error.email && text.email.length === 0}
        error={email_error || overOrder_error}
        id="email"
        label={ t('checkout.email') }
        style={{ margin: 8 }}
        fullWidth
        margin="normal"
        variant="outlined"
        value={text.email}
        onChange={(event) => {getTextValue('email', event.target.value); setEmail_error(false)}}
        helperText={email_error ? t('checkout.emailError') : overOrder_error ? t('checkout.overorderError') : '' }
      />
      <TextField
        // error={(error.verification_code && text.verification_code.length === 0) || email_error}
        error={verification_error}
        id="verification"
        label={isActive ? t('checkout.verificationSent') : t('checkout.verification')}
        fullWidth
        style={{ margin: 8 }}
        margin="normal"
        variant="outlined"
        value={text.verification_code}
        onChange={(event) => { getTextValue('verification_code', event.target.value); setVerification_error(false)}}
        helperText={verification_error ? t('checkout.verificationError') : '' }
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <Button
                onClick={() => sendVerificationCode(text.email)}
                disabled={isActive}
              >
                {isActive ? (t('checkout.resendCode')[0] + seconds + t('checkout.resendCode')[1]) : t('checkout.sendCode')}
              </Button>
            </InputAdornment>
          ),
        }}
      />
      <PhoneInput
        country={currentRegion}
        preferredCountries={['hk', 'jp', 'sg', 'tw']}
        style={{ margin: 8 }}
        value={text.phone}
        specialLabel={t('checkout.phone')}
        onChange={phone => getTextValue('phone', phone)}
        // isValid={() => {
        //   return !error.phone || text.phone.length > 0
        // }}
      />
      <div style={{display: outofstock ? 'none' : 'block'}}>
        <h3>2. {t('checkout.inStore')}</h3>
        <TextField
          id="standard-read-only-input"
          label={t('checkout.region')}
          defaultValue={defaultStaticContext.regionlist[currentRegion]}
          style={{ margin: 8 }}
          margin="normal"
          variant="outlined"
          style={{ margin: 8 }}
          disabled
        />
        {/* <FormControl
          error={error.region && selection.region.length === 0}
          fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel0} id="demo-simple-select-outlined-label">
            Region
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selection.region}
            onChange={handleChangeSelect('region')}
            labelWidth={labelWidth.labelA}
            MenuProps={{
              getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
          >
            <MenuItem value={'jp'}>Japan</MenuItem>
            <MenuItem value={'hk'}>Hong Kong</MenuItem>
            <MenuItem value={'tw'}>Tai Wan</MenuItem>
            <MenuItem value={'sg'}>Singapore</MenuItem>
          </Select>
        </FormControl> */}
        <FormControl
          // error={error.store && selection.store.length === 0}
          style={{ margin: 8, width: `calc(100% - 16px)` }}
          fullWidth variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel1} id="demo-simple-select-outlined-label">
            {t('checkout.store')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selection.store}
            onChange={handleChangeSelect('store')}
            labelWidth={labelWidth.labelB}
            MenuProps={{
            getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
          >
            {defaultStaticContext.storemap[currentRegion].map((addr, index) => 
              timemap.find(item => item.store === addr) ? (
                <MenuItem key={index} value={addr}>{t('checkout.storelist')[addr]}</MenuItem>
              ) : (
                <MenuItem key={index} disabled>{t('checkout.storelist')[addr]}</MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl
          // error={error.date && selection.date.length === 0}
          style={{ margin: 8, width: `calc(50% - 16px)` }}
          variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel2} id="demo-simple-select-outlined-label">
            {t('checkout.date')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selection.date}
            onChange={handleChangeSelect('date')}
            labelWidth={labelWidth.labelC}
            MenuProps={{
            getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
          >
            {Object.keys(defaultStaticContext.datelist).map((date, index) =>
              timemap.find(item => item.date === date && item.store === selection.store) ? (
                <MenuItem key={index} value={date}>{defaultStaticContext.datelist[date].full}</MenuItem>
              ) : (
                <MenuItem key={index} value="" disabled>{defaultStaticContext.datelist[date].full}</MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl
          // error={error.time && selection.time.length === 0}
          style={{ margin: 8, width: `calc(50% - 16px)` }}
          variant="outlined" className={classes.formControl}>
          <InputLabel ref={inputLabel3} id="demo-simple-select-outlined-label">
            {t('checkout.timeSlot')}
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selection.time}
            onChange={handleChangeSelect('time')}
            labelWidth={labelWidth.labelD}
            disabled={selection.date.length === 0}
            MenuProps={{
            getContentAnchorEl: null,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              }
            }}
          >
            {defaultStaticContext.timelist.map((time, index) =>
              timemap.find(item => item.store === selection.store && item.date === selection.date && item.time === time) ? (
                <MenuItem key={index} value={time}>{time}</MenuItem>
              ) : (
                <MenuItem key={index} disabled>{time}</MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </div>
      <FormControl
        style={{ margin: 8 }}
        // error={error.agree && !(check.checkedA)}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={check.checkedA}
              onChange={handleChangeCheck('checkedA')}
              value="checkedA"
              color="primary"
            />
          }
          label={(
            <label className="form__label">
              {t('checkout.agreeTo')} <a target="_blank" href="/condition">{t('checkout.condition')}</a> <a target="_blank" href="/privacy">{t('checkout.privacy')}</a>
            </label>
            
          )}
        />
        <FormHelperText
          style={{ color: `red` }}
        >
          {errorMessage}
        </FormHelperText>
      </FormControl>
      
    </form>
    <div className="buttonGroup stickToBottom">
      <a className="basicButton outline" href={"/" + currentDesign.product}>
        <span>{ t('back') }</span>
      </a>
      <Button className={`basicButton ${errorButton() ? '' : 'disable'}`} onClick={() => saveContent()}>
        { t('confirm') }
      </Button>
    </div>
  </div>
)}

export default Form;