import React from "react"
import { navigate } from 'gatsby';
import Form from "../components/form"
import Confirmation from "../components/confirmation"
import Save from "../components/save"
import Share from "../components/share"
import { connect } from 'react-redux';
import { updateInfo } from '../actions';

const mapStateToProps = ({ currentDesign, currentInfo, currentLang, currentRegion }) => {
  return { currentDesign, currentInfo, currentLang, currentRegion }
}

const mapDispatchToProps = dispatch => ({
  updateInfo: (infoValue) => dispatch(updateInfo(infoValue))
})

const CheckoutPage = ({ currentDesign, currentInfo, updateInfo, currentLang, currentRegion, location, handleOpenCondition }) => {
  const [stage, setStage] = React.useState('form');
  const [uuid, setUUID] = React.useState('');
  const [qrcodelink, setQrcodelink] = React.useState('');
  const [data, setData] = React.useState('');
  const [color, setColor] = React.useState('#DFD0C8');
  const { outofstock } = location.state || false
  const generateColor = () => {
    const colorArray = [ '#DFD0C8', '#E34143', '#3680B1', '#F2DF66', '#327A70', '#EE593A', '#92724E']
    const randomColor = colorArray[Math.floor(Math.random() * colorArray.length)];
    setColor(randomColor)
  }
  React.useEffect(() => {
    if (currentDesign.product === 'blank') {
      navigate('/');
    }
  }, []);
  React.useEffect(() => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      window.scrollTo(0, 0)
    }
  }, [stage])
  return (
    <div>
    { stage === 'form' ? (
      <Form
        currentInfo={currentInfo}
        currentDesign={currentDesign}
        updateInfo={updateInfo}
        setStage={setStage}
        currentRegion={currentRegion}
        currentLang={currentLang}
        data={data}
        setData={setData}
        setUUID={setUUID}
        setQrcodelink={setQrcodelink}
        outofstock={outofstock}
        handleOpenCondition={handleOpenCondition}
      />
    ) : stage === 'confirmation' ? (
      <Confirmation
        currentDesign={currentDesign}
        currentInfo={currentInfo}
        setStage={setStage}
        currentLang={currentLang}
        uuid={uuid}
        setQrcodelink={setQrcodelink}
        data={data}
        outofstock={outofstock}
        generateColor={generateColor}
        currentRegion={currentRegion}
      />
    ) : stage === 'save' ? (
      <Save
        uuid={uuid}
        qrcodelink={qrcodelink}
        setStage={setStage}
        currentInfo={currentInfo}
        outofstock={outofstock}
        color={color}
        currentDesign={currentDesign}
      />
    ) : (
      <Share
        currentDesign={currentDesign}
        currentInfo={currentInfo}
        color={color}
      />
    )}
    </div>
)}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
